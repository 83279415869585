#cars-main-container {
    background-color: $colorBg;
    display: none;
    opacity: 0;
    transition: opacity 1s;
    height: 100%;
    position: relative;
}

#canvas-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 8;
    display: none;
    pointer-events: all;

    >canvas {
        width: 100%;
        height: 100%;
    }

    header {
        display: block;
    }

    button {
        font-size: 1.6rem;
    }

    .btn-close {
        top: unset;
        right: unset;
        bottom: 4rem;
        left: 4rem;

        >svg {
            width: 3.6rem;
            height: 2rem;
            margin: 0 1.5rem -0.3rem 0;
        }
    }

    .btn-speech {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        bottom: 4rem;
        right: 4rem;

        >svg {
            display: block;
            width: 6rem;
            height: 6rem;
            margin-bottom: 1.5rem;
        }

        &.playing {
            >svg use {
                stroke: $colorBlue;
            }
        }
    }

    >svg {
        position: absolute;
        bottom: 4rem;
        left: 50%;
        transform: translateX(-50%);
        width: 8rem;
        height: 8rem;
    }
}

#platform-container {
    width: $ratioWidth;
    height: $ratioHeight;
    position: absolute;
    left: 50%;
    margin-left: -$ratioHalfWidth;
    pointer-events: all;

    @media (max-height: $mobileMaxHeight) {
        width: $ratioWidth * 1.05;
        height: $ratioHeight * 1.05;
        margin-left: $ratioHalfWidth * -1.05;
    }

    * {
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    >* {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    .walls {
        left: -5%;
        top: 11%;

        >li {
            $_scale: 1.95;
            $_w: 664 * $_scale;
            $_h: 374 * $_scale;

            position: absolute;
            background-image: url(../images/wall.png);
            width: calcPercWidth($_w);
            height: calcPercHeight($_h);
            left: 60%;

            &:nth-child(1) {
                left: unset;
                right: 50%;
            }

            &:nth-child(2) {
                transform: scaleX(-1);
            }

            >span {
                background-color: #7ecdea;
                width: calcPercWidth(3 * $_scale, $_w);
                height: calcPercHeight(251 * $_scale, $_h);
                float: right;
                margin-left: calcPercWidth(32 * $_scale, $_w);
                margin-top: 0.5%;
                transform: skew(-15.2deg);
                transition: background-color 1s linear;

                &:first-child {
                    margin-right: 28%;
                }
            }
        }
    }

    .lights {
        left: 2.5%;
        top: 10%;
        display: flex;
        flex-direction: row;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 2%, rgba(0, 0, 0, 0.75) 4%, rgba(0, 0, 0, 0) 8%);

        >li {
            background-image: url(../images/top_light.png);
            width: calcPercWidth(490);
            height: calcPercHeight(281);
            pointer-events: none;

            &:not(:first-child) {
                margin-left: -3%;
            }
        }
    }

    .stands {
        $_itm_scale: 1.25;
        $_itm_w: 790 * $_itm_scale;
        $_itm_h: 180 * $_itm_scale;
        $_w: $_itm_w * 4;
        $_h: $_itm_h;

        width: calcPercWidth($_w);
        left: 0;
        top: 57%;
        display: flex;

        >li {
            position: relative;
            width: calcPercWidth($_itm_w, $_w);
            height: calcPercHeight($_itm_h);
            display: inline-flex;
            flex-grow: 0;
            flex-shrink: 0;

            >div {
                position: absolute;

                &.main_stand {
                    background-image: url(../images/main_stand.png);
                    width: 100%;
                    height: 100%;

                    >span {
                        position: absolute;

                        &.stand {
                            background-image: url(../images/stand.png);
                            width: calcPercWidth(478 * $_itm_scale, $_itm_w);
                            height: calcPercHeight(287 * $_itm_scale, $_itm_h);
                            bottom: 59%;
                            left: 17%;

                            &.locked {
                                background-image: url(../images/stand_locked.png);
                                width: calcPercHeight(328 * $_itm_scale, $_itm_w);
                                height: calcPercHeight(51 * $_itm_scale, $_itm_h);
                                bottom: 63%;
                                left: 26%;
                            }
                        }

                        &.car {
                            $_car_w: 426 * $_itm_scale;
                            $_car_h: 285 * $_itm_scale;

                            left: 50%;
                            top: 50%;

                            &.button {

                                &:hover,
                                &:active {
                                    opacity: 1;
                                }
                            }

                            >canvas {
                                width: 100%;
                                height: 100%;
                                transform: translate3d(0, 0, 0);
                            }

                            &.car_208 {
                                width: calcPercWidth($_car_w, $_itm_w);
                                height: calcPercHeight($_car_h, $_itm_h);
                                transform: translate(-55%, -93%);
                            }

                            &.car_308 {
                                width: calcPercWidth($_car_w * 1.05, $_itm_w);
                                height: calcPercHeight($_car_h * 1.05, $_itm_h);
                                transform: translate(-54%, -85%);
                            }

                            &.car_408 {
                                width: calcPercWidth($_car_w * 0.975, $_itm_w);
                                height: calcPercHeight($_car_h * 0.975, $_itm_h);
                                transform: translate(-54%, -92%);
                            }

                            &.car_508 {
                                width: calcPercWidth($_car_w * 0.95, $_itm_w);
                                height: calcPercHeight($_car_h * 0.95, $_itm_h);
                                transform: translate(-54%, -94%);
                            }

                            &.car_2008 {
                                width: calcPercWidth($_car_w * 0.975, $_itm_w);
                                height: calcPercHeight($_car_h * 0.975, $_itm_h);
                                transform: translate(-55%, -93%);
                            }

                            &.car_3008 {
                                width: calcPercWidth($_car_w * 0.975, $_itm_w);
                                height: calcPercHeight($_car_h * 0.975, $_itm_h);
                                transform: translate(-55%, -95%);
                            }

                            &.car_rifter {
                                width: calcPercWidth($_car_w, $_itm_w);
                                height: calcPercHeight($_car_h, $_itm_h);
                                transform: translate(-54%, -93%);
                            }

                            &.car_5008 {
                                width: calcPercWidth($_car_w, $_itm_w);
                                height: calcPercHeight($_car_h, $_itm_h);
                                transform: translate(-54%, -93%);
                            }

                            &.locked {
                                background-image: url(../images/car_locked.png);
                                width: calcPercWidth(338 * $_itm_scale, $_itm_w);
                                height: calcPercHeight(123 * $_itm_scale, $_itm_h);
                                transform: translate(-53%, -123%);
                            }
                        }
                    }
                }
            }

            >span {
                background-image: url(../images/ground_light.png);
                width: calcPercWidth(482, $_w);
                height: calcPercHeight(267, $_h);
                position: absolute;
                bottom: 16%;
                left: -20%;

                &.light-left {
                    left: 84%;
                    bottom: 42%;
                    transform: rotate(4deg);
                }
            }
        }
    }
}