$defWidth: 1920;
$defHeight: 1080;

$ratioWidth: 177.78%;
$ratioHeight: 100%;
$ratioHalfWidth: math.div($ratioWidth, 2);
$ratioHalfHeight: math.div($ratioHeight, 2);

$mobileMaxHeight: 450px;

$colorBg: black;
$colorBg2: rgba(120, 123, 128, .1);
$colorLight: white;
$colorDark: black;
$colorGray: #787b80;
$colorGray2: #b2b6bd;
$colorGray3: #55575b;
$colorGray4: #2a2a2a;
$colorBlue: #00a3e0;
$colorBlue2: #7dcdeb;
$colorBlue3: #2a506b;

$gradientBg: linear-gradient(0deg, #000000 0%, #2a2a2a 100%);
$gradientBg_90: linear-gradient(90deg, #000000 0%, #2a2a2a 100%);
$gradientBg2: linear-gradient(0deg, rgba(0, 0, 0, 0) 1%, rgba(255, 255, 255, .5) 50%, rgba(0, 0, 0, 0) 99%);

@function calcPercWidth($width, $parentWidth: $defWidth) {
    @return decimal-round(math.percentage(math.div($width, $parentWidth)), 2);
}

@function calcPercHeight($height, $parentHeight: $defHeight) {
    @return decimal-round(math.percentage(math.div($height, $parentHeight)), 2);
}

html,
body {
    height: 100%;
    overflow: hidden;
    background: $colorBg;
    color: $colorLight;
    font-family: $fontPeugeot;
    font-weight: 400;
    font-size: 0.9vh;

    @media (max-height: $mobileMaxHeight) {
        font-size: 4px;
    }

    --chat-bot-height: 0rem;

    &.has-chat-bot {
        --chat-bot-height: 4.5rem;
    }
}

* {
    padding: 0;
    margin: 0;
    list-style: none;
    text-decoration: none;
    border: none;
    background: none;
    background-repeat: no-repeat;
    pointer-events: none;
    // appearance: none;
}

iframe {
    pointer-events: all;
}

@mixin button {
    pointer-events: all;
    cursor: pointer;
    color: inherit;
    font-family: inherit;

    &:hover {
        opacity: 0.75;
    }

    &:active {
        opacity: 0.6;
    }

    &.disabled,
    &.disabled-forced {
        pointer-events: none;
        opacity: 0.5;
    }
}

@mixin unbutton() {
    pointer-events: unset;
    cursor: auto;

    &:hover,
    &:active,
    &.disabled,
    &.disabled-forced {
        pointer-events: auto;
        opacity: unset;
    }
}

a,
button,
.button {
    @include button();
}

input,
select {
    border: 1px solid $colorGray;
    font-size: 1.4rem;
    font-family: inherit;

    ::placeholder {
        color: $colorGray;
    }

    &[type="radio"],
    &[type="checkbox"] {
        width: 2rem;
        height: 2rem;
        vertical-align: middle;
        /* position: relative;

        &:checked {
            &::after {
                content: '';
                display: block;
                background-color: $colorGray;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 80%;
                height: 80%;
                transform: translate(-50%, -50%);
            }
        } */
    }

    /* &[type="radio"] {
        border-radius: 50%;
        &:checked::after {
            border-radius: 50%;
        }
    } */
    &[type="text"],
    &[type="tel"],
    &[type="email"] {
        padding: 1rem 1.5rem;
    }
}

select {
    padding: 1rem 1.5rem;

    option {
        font-family: $fontPeugeot;
        font-size: 14px;
    }
}

use {
    fill: $colorLight;
    stroke: $colorLight;
}

h1 {
    font-weight: 700;
}

a,
input,
select,
label,
h1,
h2,
li,
p {
    pointer-events: all;
}

@mixin car-submenu-btn-style {
    color: $colorLight;
    font-size: 1.2rem;
    font-family: $fontPeugeot;
    width: 9.5rem;

    svg {
        width: 5rem;
        height: 3.5rem;
    }

    span {
        display: block;
        margin-top: 1rem;
    }
}

@keyframes effect-water-item {
    0% {
        opacity: 0;
        transform: scale(0.75);
    }

    40% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(1.5);
    }
}

@mixin effect-water-item($delay: 0s, $duration: 1s) {
    animation: effect-water-item $duration infinite linear $delay;
}

@mixin pin-cont() {
    position: absolute;

    >li {
        position: absolute;

        >ul {
            width: 3.5rem;
            height: 3.5rem;

            &::after {
                content: "";
                background-color: rgb(0, 163, 224, 0.3);
                border-radius: 50%;
                width: 50%;
                height: 50%;
                display: block;
                transform: translate(50%, 50%);
            }

            >li {
                border-width: 2px;
            }
        }
    }
}

ul.effect-water {
    position: absolute;
    width: 7rem;
    height: 7rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    li {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: 1px solid $colorBlue;
        border-radius: 50%;
        opacity: 0;

        &:nth-child(1) {
            @include effect-water-item(0s, 1.8s);
        }

        &:nth-child(2) {
            @include effect-water-item(0.6s, 1.8s);
        }

        &:nth-child(3) {
            @include effect-water-item(1.2s, 1.8s);
        }

        &:nth-child(4) {
            @include effect-water-item(1.8s, 1.8s);
        }
    }
}

.btn-close {
    @include button();
    padding: 1rem;
    position: fixed;
    top: 4rem;
    right: 4rem;

    svg {
        width: 2rem;
        height: 2rem;
    }
}

.swiper-grab {
    cursor: grab;
    pointer-events: all;

    &:active {
        cursor: grabbing;
    }
}

label.checkbox-slide {
    display: flex;
    flex-direction: row;

    $icoSize: 2rem;

    span {
        vertical-align: middle;

        &.round {
            display: inline-block;
            border-radius: 6rem;
            background-color: $colorGray2;
            transition: 0.2s;
            width: 6rem;
            height: 3rem;
            margin-left: 2rem;
            position: relative;

            &::before {
                content: "";
                height: $icoSize;
                width: $icoSize;
                background-color: $colorLight;
                transition: 0.2s;
                position: absolute;
                top: 0.5rem;
                left: 0.5rem;
                border-radius: 50%;
            }

            &::after {
                content: "\2714";
                color: $colorLight;
                position: absolute;
                left: 1rem;
                top: -0.2rem;
                font-weight: 400;
                font-size: $icoSize;
                display: none;
            }
        }
    }

    input {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;

        &:checked+span.round {
            background-color: $colorBlue2;

            &::before {
                left: calc(100% - $icoSize - 0.5rem);
            }

            &::after {
                display: block;
            }
        }
    }
}

#main-container {
    width: 100%;
    height: 100%;
}

#loading {
    position: fixed;
    background: rgba(0, 0, 0, 0.75) url(../images/loading_logo.png) no-repeat center;
    background-size: math.div(21.5rem, 1.5);
    width: 100%;
    height: 100%;
    transition: opacity 0.5s linear;
    z-index: 20;
    pointer-events: all;
}

#templates {
    display: none;
}

#landscape-alert {
    display: none;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    >svg {
        width: 102px;
        height: 112px;
    }

    >h2 {
        font-size: 20px;
        padding: 25px 0 50px 0;
    }

    >div {
        position: relative;
        background-color: $colorBlue;
        padding: 15px;
        border-radius: 50%;
        margin: 0 0 50px 0;

        >svg {
            width: 34px;
            height: 35px;
        }

        >ul {
            width: 150%;
            height: 150%;
        }
    }

    >span {
        font-size: 12px;

        >b {
            font-size: 14px;
        }
    }
}

#assistboxIframeModal, #assistboxConnectButton{
    pointer-events: all;
    * {
        pointer-events: all;
    }
}
#assistboxConnectButton{
    display: none !important;
    &.show {
        display: flex !important;
    }
}
#assistboxChatNotificationIcon {
    right: 0 !important
}

@media (max-aspect-ratio: 5/4) {
    #landscape-alert {
        display: flex;
    }

    #cars-main-container,
    #ui-container {
        display: none !important;
    }
}