&.views {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    >section {
        display: none;
        width: 100%;
        height: 100%;
        pointer-events: all;
        overflow-x: hidden;
        overflow-y: auto;

        &.overlay {
            position: absolute;
            z-index: 9;
            background-color: rgba(0, 0, 0, 0.75);

            * {
                pointer-events: all;
            }

            & button.overlay-inside {
                top: -3.5rem;
                right: -3.5rem;
                position: absolute;
                margin: 0;
                padding: 1rem;
                background: none;
            }
        }

        @mixin menu-overlay {
            &::before {
                content: "";
                background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 15%, rgba(0, 0, 0, 0) 60%);
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 0;
            }

            >ul {
                display: inline-flex;
                position: absolute;
                z-index: 9;
                bottom: $car_submenu_height;
                transform: translateX(calc(50vw - 50%));
                border: 2px solid $colorGray4;
                background: $gradientBg_90;

                >li {
                    @include button();

                    font-weight: 300;
                    font-size: 1.2rem;
                    padding: 3.5rem 3rem 2.5rem 3rem;

                    &:hover {
                        color: $colorBlue;
                    }
                }
            }
        }

        &.menu-colors {
            @include menu-overlay();

            >ul>li {
                >span {
                    position: relative;
                    width: 3rem;
                    height: 3rem;
                    display: inline-block;
                    vertical-align: middle;
                    border-radius: 5px;
                    margin-top: -.5rem;
                    margin-right: 1rem;

                    &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        border-radius: 5px;
                        background-image: linear-gradient(55deg,
                                rgba(0, 0, 0, 0.6) 0%,
                                rgba(0, 0, 0, 0.4) 55%,
                                rgba(0, 0, 0, 0) 100%);
                    }
                }

                &:hover {
                    opacity: 1;
                    color: unset;

                    >span::after {
                        border: 2px solid $colorBlue;
                    }
                }

                &.disabled {
                    color: $colorGray;

                    >span {
                        opacity: 0.5;
                    }
                }

                &.selected {
                    @include unbutton();

                    >span::after {
                        border: 2px solid $colorBlue;
                    }
                }
            }
        }

        &.menu-general-info, &.menu-price-info {
            @include menu-overlay();

            >ul {
                border-right: unset;

                >li {
                    padding-left: 10rem;
                    padding-right: 10rem;
                    border-right: 2px solid $colorGray4;
                }
            }
        }

        &.carspecs {
            .btn-close {
                top: 2rem;
                right: 2rem;

                svg use {
                    stroke: $colorDark;
                }
            }

            >div {
                display: none;
                background-color: $colorLight;
                width: 25%;
                min-width: 47rem;
                min-height: 100%;
                float: right;
                position: relative;

                &.float-left {
                    float: left;
                }

                >div.content {
                    color: $colorDark;
                    font-size: 1.4rem;
                    margin: 6rem 0;

                    >section {
                        margin: 0 4rem;
                        border-bottom: 1px solid #ebebeb;

                        &[data-interior] {
                            display: none;
                        }

                        >h1 {
                            @include button();
                            display: inline-block;
                            font-size: 1.4rem;
                            padding: 2rem 0;
                            // transition: font-size 0.35s linear;

                            &:hover {
                                color: $colorBlue;
                                opacity: 1;
                            }
                        }

                        >div {
                            overflow: hidden;
                            /* visibility: hidden;
                            height: 0; */
                            display: none;
                            height: 100%;
                            transition: height 0.5s ease-out;

                            >div.gallery-module {
                                width: 37.6rem;

                                >ul>li {
                                    text-align: left;

                                    >img {
                                        height: 25rem;
                                    }
                                }
                            }

                            >h2, >div.gallery-module>ul>li>span>h2 {
                                font-size: 1.4rem;
                                padding: 2rem 0;
                            }
                            
                            >span {
                                display: block;
                                pointer-events: all;
                                padding-bottom: 2rem;
                            }

                            ol {
                                margin: .5rem 0;

                                >li {
                                    margin: .25rem 0 0 2rem;
                                    list-style: disc;
                                }
                            }
                        }

                        &.selected {
                            >h1 {
                                @include unbutton();
                                color: unset;
                                pointer-events: all;
                                font-size: 2.4rem;
                            }

                            >div {
                                // visibility: visible;
                                display: block;
                            }
                        }

                        img[data-color-filter] {
                            display: none;
                        }

                        img[data-color-filter].selected {
                            display: block;
                        }
                    }
                }

                &.interior {
                    >div.content {
                        >section {
                            display: none;

                            &[data-interior] {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        &.gallery,
        &.video {
            justify-content: center;
            align-items: center;
            flex-direction: column;

            >h1 {
                font-size: 3.2rem;
                text-align: left;
                width: 94.8rem;
                display: inline-block;
                margin-bottom: 1.5rem;
            }

            div.gallery-module {
                display: inline-block;
                width: 94.8rem;
                height: 63rem;
            }

            &.video {
                div.gallery-module {
                    height: unset;

                    >ul {
                        height: 53rem;
                    }

                    >div.pager-video {
                        width: 100%;
                        font-size: 1.2rem;
                        padding: 1.5rem 0 0 0;

                        >ul {
                            display: flex;

                            >li {
                                @include button();
                                width: 14.5rem;

                                &:not(&:first-child) {
                                    margin-left: 1rem;
                                }

                                >img {
                                    width: 100%;
                                    border: 2px solid rgba(0, 0, 0, 0);
                                }

                                >span {
                                    margin-top: 1rem;
                                    display: block;
                                }

                                &.selected {
                                    @include unbutton();

                                    >img {
                                        border: 2px solid $colorBlue;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.videochat {
            justify-content: center;
            align-items: center;
            flex-direction: column;

            >div {
                width: 80%;
                height: 90%;
                display: inline-block;
                position: relative;
                background-color: $colorDark;
                
                >iframe {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}