@font-face {
    font-family: "peugeot_new";
    src: url("./fonts/peugeotnew-light-webfont.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "peugeot_new";
    src: url("./fonts/peugeotnew-regular-webfont.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "peugeot_new";
    src: url("./fonts/peugeotnew-bold-webfont.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
}

$fontPeugeot: "peugeot_new", Arial;
