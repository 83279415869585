$car_submenu_height: 15rem;

#ui-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    div.gallery-module {
        >ul {
            width: 100%;
            height: 100%;
            position: relative;

            >li {
                // position: absolute;
                left: 0;
                top: 0;
                display: none;
                width: 100%;
                height: 100%;
                text-align: center;
                background-image: url(../images/loading.png);
                background-size: 5rem;
                background-position: center;

                >img,
                >video,
                >iframe {
                    width: auto;
                    height: 100%;
                    margin-left: 50%;
                    transform: translateX(-50%);
                }

                >video {
                    display: none;
                }

                >iframe {
                    pointer-events: all;
                    width: 100%;
                }

                >button {
                    background: $colorBlue;
                    border-radius: 50%;
                    width: 6rem;
                    height: 6rem;
                    position: absolute;
                    top: calc(50% - 3rem);
                    left: calc(50% - 3rem);

                    svg {
                        width: 1.8rem;
                        height: 2.4rem;
                        position: absolute;
                        left: calc(50% - 0.7rem);
                        top: calc(50% - 1.2rem);
                    }
                }

                &.playing {

                    >img,
                    >button {
                        display: none;
                    }

                    >video {
                        display: unset;
                    }
                }
            }
        }

        .pager {
            width: 100%;
            font-size: 1.4rem;
            color: $colorBlue;
            text-align: center;
            padding: 1.5rem 0 0 0;

            >svg {
                width: 1.8rem;
                height: 1.8rem;
                margin: 0 0 0 1.5rem;
                transform: translateY(15%);

                &.left {
                    margin: 0 1.5rem 0 0;
                    transform: translateY(15%) scaleX(-1);
                }

                use {
                    stroke: $colorDark;
                }
            }

            &.light {
                color: $colorLight;

                use {
                    stroke: $colorLight;
                }
            }
        }
    }

    div.platform-menu {
        position: absolute;
        bottom: 11.7rem;
        top: unset;
        width: 100%;
        height: unset;

        >span {
            font-size: 1.5rem;
            margin-top: 4.5rem;
            display: block;
        }

        ul.line {
            display: block;
            width: 100%;
            display: flex;

            >li {
                background: #999;
                height: 2px;
                margin: 0 5px;
                flex-grow: 0;
                pointer-events: none;

                &:nth-child(1),
                &:nth-child(7) {
                    width: 1rem;
                    background: rgba(255, 255, 255, .15);
                }

                &:nth-child(2),
                &:nth-child(6) {
                    width: 3rem;
                    background: rgba(255, 255, 255, .25);
                }

                &:nth-child(3),
                &:nth-child(5) {
                    width: 5rem;
                    background: rgba(255, 255, 255, .35);
                }

                &.dots {
                    position: relative;
                    flex-grow: 1;

                    >.slider {
                        width: auto;
                        height: 3.2rem;
                        transform: translate(-50%, -50%);
                        position: absolute;
                        left: 20%;
                        top: 1px;
                    }

                    >ul>li {
                        position: absolute;
                        bottom: 0;

                        >button {
                            color: #999;
                            text-align: center;
                            transform: translateX(-50%);

                            &::before {
                                content: '';
                                width: 10px;
                                height: 10px;
                                border-radius: 50%;
                                background-color: #999;
                                position: absolute;
                                left: calc(50% - 5px);
                                bottom: -4px;
                            }

                            >img {
                                opacity: 0;
                                width: 20.8rem;
                                height: auto;
                                position: absolute;
                                bottom: 1rem;
                                left: calc(50% - 20.8rem / 2);
                                border: 1px solid #353536;
                                box-shadow: 0 0 1rem rgb(55, 183, 228, .25);
                                transition: opacity .4s linear;
                            }

                            >span {
                                position: relative;
                                pointer-events: all;
                                display: block;
                                font-size: 1.8rem;
                                font-weight: 300;
                                padding-bottom: 2rem;
                            }

                            &:hover {
                                opacity: 1;
                                color: #fff;

                                &::before {
                                    background-color: $colorBlue2;
                                }

                                >img {
                                    opacity: 1;
                                }

                                >span {
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.car {
            display: none;
            bottom: $car_submenu_height;

            li>ul>li:last-child {
                left: 100%;
            }
        }
    }

    >section {
        display: none;
        position: absolute;

        &.entrance {
            display: block;
            opacity: 0;
            background-color: $colorBg;
            background-image: url(../images/entrance_bg.jpg);
            background-size: cover;
            background-position: center bottom;
            text-align: center;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: black;
                opacity: .25;
                pointer-events: none;
            }

            >svg {
                width: 19.5rem;
                height: 21.4rem;
                padding-top: 15.5rem;
            }

            >h1 {
                font-size: 4.1rem;
                margin: 3.5rem 0 2rem 0;
            }

            >span {
                font-size: 1.7rem;
                width: 90rem;
                display: inline-block;
                pointer-events: all;
            }

            >a {
                position: absolute;
                left: 7rem;
                bottom: 6rem; //calc(6rem + var(--chat-bot-height));
                font-size: 2rem;
            }

            >button {
                font-size: 1.7rem;
                font-weight: 700;
                text-align: center;
                position: absolute;
                left: calc(50% - 33.7rem / 2);
                bottom: 8rem;
                width: 33.7rem;
                height: 6.5rem;
                padding-top: .5rem;
                border: 3px solid #38bae8;
                border-radius: 3.3rem;
                box-shadow: 0 0 .6rem #37b7e4;
                transition: box-shadow .3s linear, background-color .3s linear;

                svg {
                    transform: scaleX(-1) translateY(-10%);
                    width: 2.85rem;
                    height: 2.8rem;
                    vertical-align: middle;
                    margin-left: 1rem;
                    stroke-width: 2px;
                }

                &:hover,
                &:active {
                    background-color: #38bae8;
                }
            }

            >div {
                position: absolute;
                left: 6rem;
                bottom: 11rem; //calc(5rem + var(--chat-bot-height));

                >button {
                    @include car-submenu-btn-style();
                }
            }
        }

        &.intro {
            width: 100%;
            height: 100%;
            text-align: center;

            >h1 {
                margin-top: 28rem;
                display: inline-block;

                >img {
                    width: 84.3rem;
                    height: 8.1rem;
                }
            }

            div>p {
                display: inline-block;
                margin-top: 1rem;
                font-size: 2.8rem;
            }

            @media (max-height: $mobileMaxHeight) {}
        }

        &.car {
            width: 100%;
            height: 100%;

            >img.title {
                position: absolute;
                width: auto;
                height: 14rem;
                left: 50%;
                top: 18%;
                transform: translateX(-50%);
            }

            >img.ico360 {
                position: absolute;
                width: auto;
                height: 16.6rem;
                left: 48%;
                bottom: 30%;
                transform: translateX(-50%);
            }

            >ul.pin-cont,
            >ul.pin-cont-interior {
                @include pin-cont();

                &.pin-cont-interior {
                    display: none;

                    >li {
                        z-index: 8;
                    }
                }
            }

            >button {

                &.interior {
                    @include car-submenu-btn-style();
                    position: absolute;
                    left: 50%;

                    margin-left: 38rem;
                    bottom: 50%;

                    ul.effect-water {
                        top: 55%;
                        width: 11rem;
                        height: 11rem;
                    }

                    span {
                        margin-top: .5rem;
                        font-size: 1rem;
                        color: $colorGray2;
                    }
                }
            }

            >div.desc {
                $_w: 40rem;
                $_padR: 6rem;
                $_padL: 6rem;

                position: absolute;
                width: $_w;
                left: 0;
                bottom: 35%;
                background-color: rgba(31, 26, 26, 0.7);
                padding: 0 $_padR 5rem $_padL;
                // transform: skew(0, -1deg);
                transition: left .4s ease-in-out;
                border-top: 1px solid $colorGray3;
                border-bottom: 1px solid $colorGray3;

                @media (max-height: $mobileMaxHeight) {
                    margin-right: 34rem;
                }

                >h1 {
                    font-size: 2.8rem;
                    padding: 3rem $_padL 3rem $_padL;
                    background-color: rgba(0, 0, 0, .25);
                    margin-left: -$_padL;
                    margin-right: -$_padR;
                }

                >h2 {
                    font-size: 2.5rem;
                    font-weight: 400;
                    margin: 2rem 0;
                }

                >ul {
                    margin-left: 1.5rem;
                    color: #dcdcdc;

                    >li {
                        list-style-type: disc;
                        list-style-position: outside;
                        font-size: 1.5rem;
                        padding-left: 1rem;

                        &:not(:first-child) {
                            margin-top: 1rem;
                        }
                    }
                }

                >div {
                    margin-top: 1rem;
                    color: #dcdcdc;
                }

                >button {

                    &.desc-show-hide {
                        font-size: 1.1rem;
                        color: #929192;
                        position: absolute;
                        right: 0;
                        top: -1px;
                        width: 5rem;
                        height: calc(100% + 2px);
                        background: $gradientBg;

                        >span {
                            >svg {
                                width: 1.25rem;
                                height: 1.25rem;
                                margin: 0 .5rem 0 0;
                                transition: transform .4s ease-in-out;
                                transform: rotate(-90deg);
                                vertical-align: middle;
                            }

                            position: absolute;
                            white-space: nowrap;
                            transform: translate(-50%) rotate(-90deg);
                            pointer-events: all;

                            >span:nth-child(3) {
                                display: none;
                            }
                        }
                    }

                    &.contact {
                        @include car-submenu-btn-style();

                        &:hover div {
                            opacity: .85;
                        }

                        width: unset!important;
                        margin-top: 4rem;

                        div {
                            border: 1px solid $colorBlue;
                            border-radius: 4px;
                            color: $colorLight;
                            background-color: $colorBlue;
                            padding: 1.2rem 6.0rem 0.9rem 5.5rem;
                            font-size: 1.4rem;
                        }

                        svg {
                            vertical-align: middle;
                            margin-right: .5rem;
                            margin-bottom: .5rem;
                            width: 2rem;
                            height: 2rem;

                            use {
                                fill: $colorLight;
                            }
                        }

                        span {
                            display: unset;
                        }
                    }
                }

                &.hide {
                    left: -$_w - $_padR - 1rem;

                    >button.desc-show-hide>span {
                        >span:nth-child(3) {
                            display: unset;
                        }

                        >span:nth-child(2) {
                            display: none;
                        }

                        >svg {
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }
    }

    >div {
        &.car-submenu {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: $car_submenu_height;
            display: none; // flex
            justify-content: center;
            align-items: flex-start;
            // z-index: 10;

            @media (max-height: $mobileMaxHeight) {
                z-index: 7 !important;
            }

            >button {
                @include car-submenu-btn-style();
                height: 100%;
                position: relative;
                width: unset!important;
                >div {
                    $_p_top: 4.5rem;
                    width: 16rem;
                    height: calc(100% - $_p_top);
                    padding-top: $_p_top;
                }
                
                &:first-child {
                    >div {
                        border-left: 1px solid $colorGray4;
                    }
                }

                &.last-menu-item {
                    >div {
                        border-right: 1px solid $colorGray4;
                    }
                }

                &:not(.last-menu-item) {
                    >div {
                        &:before {
                            content: '';
                            position: absolute;
                            width: 2px;
                            left: 100%;
                            top: 0;
                            bottom: 0;
                            background: $gradientBg2;
                        }
                    }
                }

                &.selected,
                &:hover {
                    color: $colorBlue;

                    svg use {
                        stroke: $colorBlue;
                        fill: $colorBlue;
                    }
                }

                &.selected {
                    &::before {
                        content: '';
                        position: absolute;
                        border-bottom: 2px solid $colorBlue;
                        top: 80%;
                        bottom: 0;
                        left: 0;
                        right: 0;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        height: 1px;
                        bottom: -10%;
                        left: 30%;
                        right: 30%;
                        box-shadow: 0 0 40px 20px $colorBlue;
                    }
                }
            }
        }

        @import "views";
    }

    #menu-videochat{
        position: absolute;
        right: 1rem;
        top: calc(100vh - 37.5rem);
        display: none; // flex
        align-items: center;
        z-index: 1;

        button,a {
            position: unset;
            padding: 0;
            font-size: 1.2rem;
        }

        >button{
            $colorBorder: rgb(0, 163, 224, 0.2);
            $outline: .5rem solid $colorBorder;

            display: inline-block;
            background: none;

            &.left{
                color: $colorLight;
                height: 6.5rem;
                margin-right: 2rem;
                border-radius: 5px;
                background: $gradientBg_90;
                outline: 1px solid $colorBlue3;
                position: relative;
                display: inline-flex;
                align-items: center;
                padding: 0 1.5rem 0 1.2rem;

                &::before {
                    content: '';
                    border-radius: 5px;
                    outline: $outline;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                &::after {
                    $halfW: .5rem;
                    content: '';
                    border-top: $halfW solid $colorBlue;
                    border-right: $halfW solid $colorBlue;
                    border-bottom: $halfW solid transparent;
                    border-left: $halfW solid transparent;
                    background: transparent;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translate(95%, -30%) rotate(45deg);
                }

                &:hover{
                    opacity: 1;
                    color: $colorBlue;
                }

                >a{
                    border-radius: 50%;
                    background: $colorBg2;
                    width: 3.2rem;
                    height: 3.2rem;
                    margin: 0 1.5rem 0 0;
                    position: relative;

                    svg {
                        width: 1rem;
                        height: 1rem;
                        margin: 0;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                >span{
                    text-align: left;
                    display: inline-block;
                }
            }
            &.right{
                overflow: hidden;
                width: 9rem;
                height: 9rem;
                border-radius: 50%;
                outline: $outline;

                >img{
                    width: auto;
                    height: 100%;
                }
            }
        }
    }
}