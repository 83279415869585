header {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    >svg {
        $w: 8.2rem;
        width: $w;
        height: 9rem;
        position: absolute;
        left: calc(50% - $w / 2);
        top: 2rem;
    }

    button,
    a {
        position: absolute;

        font-size: 1.2rem;

        svg {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 1rem;
            vertical-align: middle;
            transform: scaleX(-1) translateY(-10%);

            use {
                stroke: $colorGray2;
            }
        }

        background-color: $colorBg2;
        padding: 4.9rem 5rem;
        top: 0;
        left: 0;
        color: $colorGray;

        &[data-template="button-map"] {
            left: unset;
            right: 0;

            svg {
                width: 2.5rem;
                height: 2.5rem;
                transform: translateY(-10%);
                margin-right: 1.5rem;
            }
        }

        &#menu-back-to-showroom {
            color: unset;
            padding: 0.5rem 2.5rem .2rem 2.5rem;
            background-color: unset;

            top: calc(100vh - 8.4rem);
            left: calc(6rem - 2.5rem);
        }
    }

    >div {
        >button {
            display: none;
        }
    }
}