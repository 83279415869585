@font-face {
  font-family: peugeot_new;
  src: url("peugeotnew-light-webfont.0d0bdd72.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: peugeot_new;
  src: url("peugeotnew-regular-webfont.6465f688.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: peugeot_new;
  src: url("peugeotnew-bold-webfont.bc8f86c2.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

html, body {
  color: #fff;
  --chat-bot-height: 0rem;
  background: #000;
  height: 100%;
  font-family: peugeot_new, Arial;
  font-size: .9vh;
  font-weight: 400;
  overflow: hidden;
}

@media (max-height: 450px) {
  html, body {
    font-size: 4px;
  }
}

html.has-chat-bot, body.has-chat-bot {
  --chat-bot-height: 4.5rem;
}

* {
  pointer-events: none;
  background: no-repeat;
  border: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}

iframe {
  pointer-events: all;
}

a, button, .button {
  pointer-events: all;
  cursor: pointer;
  color: inherit;
  font-family: inherit;
}

a:hover, button:hover, .button:hover {
  opacity: .75;
}

a:active, button:active, .button:active {
  opacity: .6;
}

a.disabled, a.disabled-forced, button.disabled, button.disabled-forced, .button.disabled, .button.disabled-forced {
  pointer-events: none;
  opacity: .5;
}

input, select {
  border: 1px solid #787b80;
  font-family: inherit;
  font-size: 1.4rem;
}

input ::placeholder, select ::placeholder {
  color: #787b80;
}

input[type="radio"], input[type="checkbox"], select[type="radio"], select[type="checkbox"] {
  vertical-align: middle;
  width: 2rem;
  height: 2rem;
}

input[type="text"], input[type="tel"], input[type="email"], select[type="text"], select[type="tel"], select[type="email"], select {
  padding: 1rem 1.5rem;
}

select option {
  font-family: peugeot_new, Arial;
  font-size: 14px;
}

use {
  fill: #fff;
  stroke: #fff;
}

h1 {
  font-weight: 700;
}

a, input, select, label, h1, h2, li, p {
  pointer-events: all;
}

@keyframes effect-water-item {
  0% {
    opacity: 0;
    transform: scale(.75);
  }

  40% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

ul.effect-water {
  width: 7rem;
  height: 7rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

ul.effect-water li {
  opacity: 0;
  border: 1px solid #00a3e0;
  border-radius: 50%;
  position: absolute;
  inset: 0;
}

ul.effect-water li:first-child {
  animation: 1.8s linear infinite effect-water-item;
}

ul.effect-water li:nth-child(2) {
  animation: 1.8s linear .6s infinite effect-water-item;
}

ul.effect-water li:nth-child(3) {
  animation: 1.8s linear 1.2s infinite effect-water-item;
}

ul.effect-water li:nth-child(4) {
  animation: 1.8s linear 1.8s infinite effect-water-item;
}

.btn-close {
  pointer-events: all;
  cursor: pointer;
  color: inherit;
  padding: 1rem;
  font-family: inherit;
  position: fixed;
  top: 4rem;
  right: 4rem;
}

.btn-close:hover {
  opacity: .75;
}

.btn-close:active {
  opacity: .6;
}

.btn-close.disabled, .btn-close.disabled-forced {
  pointer-events: none;
  opacity: .5;
}

.btn-close svg {
  width: 2rem;
  height: 2rem;
}

.swiper-grab {
  cursor: grab;
  pointer-events: all;
}

.swiper-grab:active {
  cursor: grabbing;
}

label.checkbox-slide {
  flex-direction: row;
  display: flex;
}

label.checkbox-slide span {
  vertical-align: middle;
}

label.checkbox-slide span.round {
  background-color: #b2b6bd;
  border-radius: 6rem;
  width: 6rem;
  height: 3rem;
  margin-left: 2rem;
  transition: all .2s;
  display: inline-block;
  position: relative;
}

label.checkbox-slide span.round:before {
  content: "";
  background-color: #fff;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  transition: all .2s;
  position: absolute;
  top: .5rem;
  left: .5rem;
}

label.checkbox-slide span.round:after {
  content: "✔";
  color: #fff;
  font-size: 2rem;
  font-weight: 400;
  display: none;
  position: absolute;
  top: -.2rem;
  left: 1rem;
}

label.checkbox-slide input {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

label.checkbox-slide input:checked + span.round {
  background-color: #7dcdeb;
}

label.checkbox-slide input:checked + span.round:before {
  left: calc(100% - 2.5rem);
}

label.checkbox-slide input:checked + span.round:after {
  display: block;
}

#main-container {
  width: 100%;
  height: 100%;
}

#loading {
  z-index: 20;
  pointer-events: all;
  background: #000000bf url("loading_logo.98bb4469.png") center / 14.3333rem no-repeat;
  width: 100%;
  height: 100%;
  transition: opacity .5s linear;
  position: fixed;
}

#templates {
  display: none;
}

#landscape-alert {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: none;
}

#landscape-alert > svg {
  width: 102px;
  height: 112px;
}

#landscape-alert > h2 {
  padding: 25px 0 50px;
  font-size: 20px;
}

#landscape-alert > div {
  background-color: #00a3e0;
  border-radius: 50%;
  margin: 0 0 50px;
  padding: 15px;
  position: relative;
}

#landscape-alert > div > svg {
  width: 34px;
  height: 35px;
}

#landscape-alert > div > ul {
  width: 150%;
  height: 150%;
}

#landscape-alert > span {
  font-size: 12px;
}

#landscape-alert > span > b {
  font-size: 14px;
}

#assistboxIframeModal, #assistboxConnectButton, #assistboxIframeModal *, #assistboxConnectButton * {
  pointer-events: all;
}

#assistboxConnectButton {
  display: none !important;
}

#assistboxConnectButton.show {
  display: flex !important;
}

#assistboxChatNotificationIcon {
  right: 0 !important;
}

@media (max-aspect-ratio: 5 / 4) {
  #landscape-alert {
    display: flex;
  }

  #cars-main-container, #ui-container {
    display: none !important;
  }
}

header {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

header > svg {
  width: 8.2rem;
  height: 9rem;
  position: absolute;
  top: 2rem;
  left: calc(50% - 4.1rem);
}

header button, header a {
  color: #787b80;
  background-color: #787b801a;
  padding: 4.9rem 5rem;
  font-size: 1.2rem;
  position: absolute;
  top: 0;
  left: 0;
}

header button svg, header a svg {
  vertical-align: middle;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
  transform: scaleX(-1)translateY(-10%);
}

header button svg use, header a svg use {
  stroke: #b2b6bd;
}

header button[data-template="button-map"], header a[data-template="button-map"] {
  left: unset;
  right: 0;
}

header button[data-template="button-map"] svg, header a[data-template="button-map"] svg {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1.5rem;
  transform: translateY(-10%);
}

header button#menu-back-to-showroom, header a#menu-back-to-showroom {
  color: unset;
  background-color: unset;
  padding: .5rem 2.5rem .2rem;
  top: calc(100vh - 8.4rem);
  left: 3.5rem;
}

header > div > button {
  display: none;
}

#ui-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#ui-container div.gallery-module > ul {
  width: 100%;
  height: 100%;
  position: relative;
}

#ui-container div.gallery-module > ul > li {
  text-align: center;
  background-image: url("loading.32d038f8.png");
  background-position: center;
  background-size: 5rem;
  width: 100%;
  height: 100%;
  display: none;
  top: 0;
  left: 0;
}

#ui-container div.gallery-module > ul > li > img, #ui-container div.gallery-module > ul > li > video, #ui-container div.gallery-module > ul > li > iframe {
  width: auto;
  height: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
}

#ui-container div.gallery-module > ul > li > video {
  display: none;
}

#ui-container div.gallery-module > ul > li > iframe {
  pointer-events: all;
  width: 100%;
}

#ui-container div.gallery-module > ul > li > button {
  background: #00a3e0;
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  position: absolute;
  top: calc(50% - 3rem);
  left: calc(50% - 3rem);
}

#ui-container div.gallery-module > ul > li > button svg {
  width: 1.8rem;
  height: 2.4rem;
  position: absolute;
  top: calc(50% - 1.2rem);
  left: calc(50% - .7rem);
}

#ui-container div.gallery-module > ul > li.playing > img, #ui-container div.gallery-module > ul > li.playing > button {
  display: none;
}

#ui-container div.gallery-module > ul > li.playing > video {
  display: unset;
}

#ui-container div.gallery-module .pager {
  color: #00a3e0;
  text-align: center;
  width: 100%;
  padding: 1.5rem 0 0;
  font-size: 1.4rem;
}

#ui-container div.gallery-module .pager > svg {
  width: 1.8rem;
  height: 1.8rem;
  margin: 0 0 0 1.5rem;
  transform: translateY(15%);
}

#ui-container div.gallery-module .pager > svg.left {
  margin: 0 1.5rem 0 0;
  transform: translateY(15%)scaleX(-1);
}

#ui-container div.gallery-module .pager > svg use {
  stroke: #000;
}

#ui-container div.gallery-module .pager.light {
  color: #fff;
}

#ui-container div.gallery-module .pager.light use {
  stroke: #fff;
}

#ui-container div.platform-menu {
  bottom: 11.7rem;
  top: unset;
  height: unset;
  width: 100%;
  position: absolute;
}

#ui-container div.platform-menu > span {
  margin-top: 4.5rem;
  font-size: 1.5rem;
  display: block;
}

#ui-container div.platform-menu ul.line {
  width: 100%;
  display: flex;
}

#ui-container div.platform-menu ul.line > li {
  pointer-events: none;
  background: #999;
  flex-grow: 0;
  height: 2px;
  margin: 0 5px;
}

#ui-container div.platform-menu ul.line > li:first-child, #ui-container div.platform-menu ul.line > li:nth-child(7) {
  background: #ffffff26;
  width: 1rem;
}

#ui-container div.platform-menu ul.line > li:nth-child(2), #ui-container div.platform-menu ul.line > li:nth-child(6) {
  background: #ffffff40;
  width: 3rem;
}

#ui-container div.platform-menu ul.line > li:nth-child(3), #ui-container div.platform-menu ul.line > li:nth-child(5) {
  background: #ffffff59;
  width: 5rem;
}

#ui-container div.platform-menu ul.line > li.dots {
  flex-grow: 1;
  position: relative;
}

#ui-container div.platform-menu ul.line > li.dots > .slider {
  width: auto;
  height: 3.2rem;
  position: absolute;
  top: 1px;
  left: 20%;
  transform: translate(-50%, -50%);
}

#ui-container div.platform-menu ul.line > li.dots > ul > li {
  position: absolute;
  bottom: 0;
}

#ui-container div.platform-menu ul.line > li.dots > ul > li > button {
  color: #999;
  text-align: center;
  transform: translateX(-50%);
}

#ui-container div.platform-menu ul.line > li.dots > ul > li > button:before {
  content: "";
  background-color: #999;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: -4px;
  left: calc(50% - 5px);
}

#ui-container div.platform-menu ul.line > li.dots > ul > li > button > img {
  opacity: 0;
  border: 1px solid #353536;
  width: 20.8rem;
  height: auto;
  transition: opacity .4s linear;
  position: absolute;
  bottom: 1rem;
  left: calc(50% - 10.4rem);
  box-shadow: 0 0 1rem #37b7e440;
}

#ui-container div.platform-menu ul.line > li.dots > ul > li > button > span {
  pointer-events: all;
  padding-bottom: 2rem;
  font-size: 1.8rem;
  font-weight: 300;
  display: block;
  position: relative;
}

#ui-container div.platform-menu ul.line > li.dots > ul > li > button:hover {
  opacity: 1;
  color: #fff;
}

#ui-container div.platform-menu ul.line > li.dots > ul > li > button:hover:before {
  background-color: #7dcdeb;
}

#ui-container div.platform-menu ul.line > li.dots > ul > li > button:hover > img {
  opacity: 1;
}

#ui-container div.platform-menu ul.line > li.dots > ul > li > button:hover > span {
  font-weight: 700;
}

#ui-container div.platform-menu.car {
  display: none;
  bottom: 15rem;
}

#ui-container div.platform-menu.car li > ul > li:last-child {
  left: 100%;
}

#ui-container > section {
  display: none;
  position: absolute;
}

#ui-container > section.entrance {
  opacity: 0;
  text-align: center;
  background-color: #000;
  background-image: url("entrance_bg.cbbc7753.jpg");
  background-position: bottom;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: block;
  top: 0;
  left: 0;
}

#ui-container > section.entrance:before {
  content: "";
  opacity: .25;
  pointer-events: none;
  background-color: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#ui-container > section.entrance > svg {
  width: 19.5rem;
  height: 21.4rem;
  padding-top: 15.5rem;
}

#ui-container > section.entrance > h1 {
  margin: 3.5rem 0 2rem;
  font-size: 4.1rem;
}

#ui-container > section.entrance > span {
  pointer-events: all;
  width: 90rem;
  font-size: 1.7rem;
  display: inline-block;
}

#ui-container > section.entrance > a {
  font-size: 2rem;
  position: absolute;
  bottom: 6rem;
  left: 7rem;
}

#ui-container > section.entrance > button {
  text-align: center;
  border: 3px solid #38bae8;
  border-radius: 3.3rem;
  width: 33.7rem;
  height: 6.5rem;
  padding-top: .5rem;
  font-size: 1.7rem;
  font-weight: 700;
  transition: box-shadow .3s linear, background-color .3s linear;
  position: absolute;
  bottom: 8rem;
  left: calc(50% - 16.85rem);
  box-shadow: 0 0 .6rem #37b7e4;
}

#ui-container > section.entrance > button svg {
  vertical-align: middle;
  stroke-width: 2px;
  width: 2.85rem;
  height: 2.8rem;
  margin-left: 1rem;
  transform: scaleX(-1)translateY(-10%);
}

#ui-container > section.entrance > button:hover, #ui-container > section.entrance > button:active {
  background-color: #38bae8;
}

#ui-container > section.entrance > div {
  position: absolute;
  bottom: 11rem;
  left: 6rem;
}

#ui-container > section.entrance > div > button {
  color: #fff;
  width: 9.5rem;
  font-family: peugeot_new, Arial;
  font-size: 1.2rem;
}

#ui-container > section.entrance > div > button svg {
  width: 5rem;
  height: 3.5rem;
}

#ui-container > section.entrance > div > button span {
  margin-top: 1rem;
  display: block;
}

#ui-container > section.intro {
  text-align: center;
  width: 100%;
  height: 100%;
}

#ui-container > section.intro > h1 {
  margin-top: 28rem;
  display: inline-block;
}

#ui-container > section.intro > h1 > img {
  width: 84.3rem;
  height: 8.1rem;
}

#ui-container > section.intro div > p {
  margin-top: 1rem;
  font-size: 2.8rem;
  display: inline-block;
}

#ui-container > section.car {
  width: 100%;
  height: 100%;
}

#ui-container > section.car > img.title {
  width: auto;
  height: 14rem;
  position: absolute;
  top: 18%;
  left: 50%;
  transform: translateX(-50%);
}

#ui-container > section.car > img.ico360 {
  width: auto;
  height: 16.6rem;
  position: absolute;
  bottom: 30%;
  left: 48%;
  transform: translateX(-50%);
}

#ui-container > section.car > ul.pin-cont, #ui-container > section.car > ul.pin-cont-interior, #ui-container > section.car > ul.pin-cont > li, #ui-container > section.car > ul.pin-cont-interior > li {
  position: absolute;
}

#ui-container > section.car > ul.pin-cont > li > ul, #ui-container > section.car > ul.pin-cont-interior > li > ul {
  width: 3.5rem;
  height: 3.5rem;
}

#ui-container > section.car > ul.pin-cont > li > ul:after, #ui-container > section.car > ul.pin-cont-interior > li > ul:after {
  content: "";
  background-color: #00a3e04d;
  border-radius: 50%;
  width: 50%;
  height: 50%;
  display: block;
  transform: translate(50%, 50%);
}

#ui-container > section.car > ul.pin-cont > li > ul > li, #ui-container > section.car > ul.pin-cont-interior > li > ul > li {
  border-width: 2px;
}

#ui-container > section.car > ul.pin-cont.pin-cont-interior, #ui-container > section.car > ul.pin-cont-interior.pin-cont-interior {
  display: none;
}

#ui-container > section.car > ul.pin-cont.pin-cont-interior > li, #ui-container > section.car > ul.pin-cont-interior.pin-cont-interior > li {
  z-index: 8;
}

#ui-container > section.car > button.interior {
  color: #fff;
  width: 9.5rem;
  margin-left: 38rem;
  font-family: peugeot_new, Arial;
  font-size: 1.2rem;
  position: absolute;
  bottom: 50%;
  left: 50%;
}

#ui-container > section.car > button.interior svg {
  width: 5rem;
  height: 3.5rem;
}

#ui-container > section.car > button.interior span {
  margin-top: 1rem;
  display: block;
}

#ui-container > section.car > button.interior ul.effect-water {
  width: 11rem;
  height: 11rem;
  top: 55%;
}

#ui-container > section.car > button.interior span {
  color: #b2b6bd;
  margin-top: .5rem;
  font-size: 1rem;
}

#ui-container > section.car > div.desc {
  background-color: #1f1a1ab3;
  border-top: 1px solid #55575b;
  border-bottom: 1px solid #55575b;
  width: 40rem;
  padding: 0 6rem 5rem;
  transition: left .4s ease-in-out;
  position: absolute;
  bottom: 35%;
  left: 0;
}

@media (max-height: 450px) {
  #ui-container > section.car > div.desc {
    margin-right: 34rem;
  }
}

#ui-container > section.car > div.desc > h1 {
  background-color: #00000040;
  margin-left: -6rem;
  margin-right: -6rem;
  padding: 3rem 6rem;
  font-size: 2.8rem;
}

#ui-container > section.car > div.desc > h2 {
  margin: 2rem 0;
  font-size: 2.5rem;
  font-weight: 400;
}

#ui-container > section.car > div.desc > ul {
  color: #dcdcdc;
  margin-left: 1.5rem;
}

#ui-container > section.car > div.desc > ul > li {
  padding-left: 1rem;
  font-size: 1.5rem;
  list-style-type: disc;
  list-style-position: outside;
}

#ui-container > section.car > div.desc > ul > li:not(:first-child) {
  margin-top: 1rem;
}

#ui-container > section.car > div.desc > div {
  color: #dcdcdc;
  margin-top: 1rem;
}

#ui-container > section.car > div.desc > button.desc-show-hide {
  color: #929192;
  background: linear-gradient(0deg, #000 0%, #2a2a2a 100%);
  width: 5rem;
  height: calc(100% + 2px);
  font-size: 1.1rem;
  position: absolute;
  top: -1px;
  right: 0;
}

#ui-container > section.car > div.desc > button.desc-show-hide > span {
  white-space: nowrap;
  pointer-events: all;
  position: absolute;
  transform: translate(-50%)rotate(-90deg);
}

#ui-container > section.car > div.desc > button.desc-show-hide > span > svg {
  vertical-align: middle;
  width: 1.25rem;
  height: 1.25rem;
  margin: 0 .5rem 0 0;
  transition: transform .4s ease-in-out;
  transform: rotate(-90deg);
}

#ui-container > section.car > div.desc > button.desc-show-hide > span > span:nth-child(3) {
  display: none;
}

#ui-container > section.car > div.desc > button.contact {
  color: #fff;
  width: 9.5rem;
  margin-top: 4rem;
  font-family: peugeot_new, Arial;
  font-size: 1.2rem;
  width: unset !important;
}

#ui-container > section.car > div.desc > button.contact svg {
  width: 5rem;
  height: 3.5rem;
}

#ui-container > section.car > div.desc > button.contact span {
  margin-top: 1rem;
  display: block;
}

#ui-container > section.car > div.desc > button.contact:hover div {
  opacity: .85;
}

#ui-container > section.car > div.desc > button.contact div {
  color: #fff;
  background-color: #00a3e0;
  border: 1px solid #00a3e0;
  border-radius: 4px;
  padding: 1.2rem 6rem .9rem 5.5rem;
  font-size: 1.4rem;
}

#ui-container > section.car > div.desc > button.contact svg {
  vertical-align: middle;
  width: 2rem;
  height: 2rem;
  margin-bottom: .5rem;
  margin-right: .5rem;
}

#ui-container > section.car > div.desc > button.contact svg use {
  fill: #fff;
}

#ui-container > section.car > div.desc > button.contact span {
  display: unset;
}

#ui-container > section.car > div.desc.hide {
  left: -47rem;
}

#ui-container > section.car > div.desc.hide > button.desc-show-hide > span > span:nth-child(3) {
  display: unset;
}

#ui-container > section.car > div.desc.hide > button.desc-show-hide > span > span:nth-child(2) {
  display: none;
}

#ui-container > section.car > div.desc.hide > button.desc-show-hide > span > svg {
  transform: rotate(90deg);
}

#ui-container > div.car-submenu {
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 15rem;
  display: none;
  position: absolute;
  bottom: 0;
}

@media (max-height: 450px) {
  #ui-container > div.car-submenu {
    z-index: 7 !important;
  }
}

#ui-container > div.car-submenu > button {
  color: #fff;
  width: 9.5rem;
  height: 100%;
  font-family: peugeot_new, Arial;
  font-size: 1.2rem;
  position: relative;
  width: unset !important;
}

#ui-container > div.car-submenu > button svg {
  width: 5rem;
  height: 3.5rem;
}

#ui-container > div.car-submenu > button span {
  margin-top: 1rem;
  display: block;
}

#ui-container > div.car-submenu > button > div {
  width: 16rem;
  height: calc(100% - 4.5rem);
  padding-top: 4.5rem;
}

#ui-container > div.car-submenu > button:first-child > div {
  border-left: 1px solid #2a2a2a;
}

#ui-container > div.car-submenu > button.last-menu-item > div {
  border-right: 1px solid #2a2a2a;
}

#ui-container > div.car-submenu > button:not(.last-menu-item) > div:before {
  content: "";
  background: linear-gradient(0deg, #0000 1%, #ffffff80 50%, #0000 99%);
  width: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
}

#ui-container > div.car-submenu > button.selected, #ui-container > div.car-submenu > button:hover {
  color: #00a3e0;
}

#ui-container > div.car-submenu > button.selected svg use, #ui-container > div.car-submenu > button:hover svg use {
  stroke: #00a3e0;
  fill: #00a3e0;
}

#ui-container > div.car-submenu > button.selected:before {
  content: "";
  border-bottom: 2px solid #00a3e0;
  position: absolute;
  inset: 80% 0 0;
}

#ui-container > div.car-submenu > button.selected:after {
  content: "";
  height: 1px;
  position: absolute;
  bottom: -10%;
  left: 30%;
  right: 30%;
  box-shadow: 0 0 40px 20px #00a3e0;
}

#ui-container > div.views {
  display: none;
  position: absolute;
  inset: 0;
}

#ui-container > div.views > section {
  pointer-events: all;
  width: 100%;
  height: 100%;
  display: none;
  overflow: hidden auto;
}

#ui-container > div.views > section.overlay {
  z-index: 9;
  background-color: #000000bf;
  position: absolute;
}

#ui-container > div.views > section.overlay * {
  pointer-events: all;
}

#ui-container > div.views > section.overlay button.overlay-inside {
  background: none;
  margin: 0;
  padding: 1rem;
  position: absolute;
  top: -3.5rem;
  right: -3.5rem;
}

#ui-container > div.views > section.menu-colors:before {
  content: "";
  z-index: 0;
  background-image: linear-gradient(0deg, #000000b3 15%, #0000 60%);
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

#ui-container > div.views > section.menu-colors > ul {
  z-index: 9;
  background: linear-gradient(90deg, #000 0%, #2a2a2a 100%);
  border: 2px solid #2a2a2a;
  display: inline-flex;
  position: absolute;
  bottom: 15rem;
  transform: translateX(calc(50vw - 50%));
}

#ui-container > div.views > section.menu-colors > ul > li {
  pointer-events: all;
  cursor: pointer;
  color: inherit;
  padding: 3.5rem 3rem 2.5rem;
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 300;
}

#ui-container > div.views > section.menu-colors > ul > li:hover {
  opacity: .75;
}

#ui-container > div.views > section.menu-colors > ul > li:active {
  opacity: .6;
}

#ui-container > div.views > section.menu-colors > ul > li.disabled, #ui-container > div.views > section.menu-colors > ul > li.disabled-forced {
  pointer-events: none;
  opacity: .5;
}

#ui-container > div.views > section.menu-colors > ul > li:hover {
  color: #00a3e0;
}

#ui-container > div.views > section.menu-colors > ul > li > span {
  vertical-align: middle;
  border-radius: 5px;
  width: 3rem;
  height: 3rem;
  margin-top: -.5rem;
  margin-right: 1rem;
  display: inline-block;
  position: relative;
}

#ui-container > div.views > section.menu-colors > ul > li > span:after {
  content: "";
  background-image: linear-gradient(55deg, #0009 0%, #0006 55%, #0000 100%);
  border-radius: 5px;
  position: absolute;
  inset: 0;
}

#ui-container > div.views > section.menu-colors > ul > li:hover {
  opacity: 1;
  color: unset;
}

#ui-container > div.views > section.menu-colors > ul > li:hover > span:after {
  border: 2px solid #00a3e0;
}

#ui-container > div.views > section.menu-colors > ul > li.disabled {
  color: #787b80;
}

#ui-container > div.views > section.menu-colors > ul > li.disabled > span {
  opacity: .5;
}

#ui-container > div.views > section.menu-colors > ul > li.selected {
  pointer-events: unset;
  cursor: auto;
}

#ui-container > div.views > section.menu-colors > ul > li.selected:hover, #ui-container > div.views > section.menu-colors > ul > li.selected:active, #ui-container > div.views > section.menu-colors > ul > li.selected.disabled, #ui-container > div.views > section.menu-colors > ul > li.selected.disabled-forced {
  pointer-events: auto;
  opacity: unset;
}

#ui-container > div.views > section.menu-colors > ul > li.selected > span:after {
  border: 2px solid #00a3e0;
}

#ui-container > div.views > section.menu-general-info:before, #ui-container > div.views > section.menu-price-info:before {
  content: "";
  z-index: 0;
  background-image: linear-gradient(0deg, #000000b3 15%, #0000 60%);
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

#ui-container > div.views > section.menu-general-info > ul, #ui-container > div.views > section.menu-price-info > ul {
  z-index: 9;
  background: linear-gradient(90deg, #000 0%, #2a2a2a 100%);
  border: 2px solid #2a2a2a;
  display: inline-flex;
  position: absolute;
  bottom: 15rem;
  transform: translateX(calc(50vw - 50%));
}

#ui-container > div.views > section.menu-general-info > ul > li, #ui-container > div.views > section.menu-price-info > ul > li {
  pointer-events: all;
  cursor: pointer;
  color: inherit;
  padding: 3.5rem 3rem 2.5rem;
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 300;
}

#ui-container > div.views > section.menu-general-info > ul > li:hover, #ui-container > div.views > section.menu-price-info > ul > li:hover {
  opacity: .75;
}

#ui-container > div.views > section.menu-general-info > ul > li:active, #ui-container > div.views > section.menu-price-info > ul > li:active {
  opacity: .6;
}

#ui-container > div.views > section.menu-general-info > ul > li.disabled, #ui-container > div.views > section.menu-general-info > ul > li.disabled-forced, #ui-container > div.views > section.menu-price-info > ul > li.disabled, #ui-container > div.views > section.menu-price-info > ul > li.disabled-forced {
  pointer-events: none;
  opacity: .5;
}

#ui-container > div.views > section.menu-general-info > ul > li:hover, #ui-container > div.views > section.menu-price-info > ul > li:hover {
  color: #00a3e0;
}

#ui-container > div.views > section.menu-general-info > ul, #ui-container > div.views > section.menu-price-info > ul {
  border-right: unset;
}

#ui-container > div.views > section.menu-general-info > ul > li, #ui-container > div.views > section.menu-price-info > ul > li {
  border-right: 2px solid #2a2a2a;
  padding-left: 10rem;
  padding-right: 10rem;
}

#ui-container > div.views > section.carspecs .btn-close {
  top: 2rem;
  right: 2rem;
}

#ui-container > div.views > section.carspecs .btn-close svg use {
  stroke: #000;
}

#ui-container > div.views > section.carspecs > div {
  float: right;
  background-color: #fff;
  width: 25%;
  min-width: 47rem;
  min-height: 100%;
  display: none;
  position: relative;
}

#ui-container > div.views > section.carspecs > div.float-left {
  float: left;
}

#ui-container > div.views > section.carspecs > div > div.content {
  color: #000;
  margin: 6rem 0;
  font-size: 1.4rem;
}

#ui-container > div.views > section.carspecs > div > div.content > section {
  border-bottom: 1px solid #ebebeb;
  margin: 0 4rem;
}

#ui-container > div.views > section.carspecs > div > div.content > section[data-interior] {
  display: none;
}

#ui-container > div.views > section.carspecs > div > div.content > section > h1 {
  pointer-events: all;
  cursor: pointer;
  color: inherit;
  padding: 2rem 0;
  font-family: inherit;
  font-size: 1.4rem;
  display: inline-block;
}

#ui-container > div.views > section.carspecs > div > div.content > section > h1:hover {
  opacity: .75;
}

#ui-container > div.views > section.carspecs > div > div.content > section > h1:active {
  opacity: .6;
}

#ui-container > div.views > section.carspecs > div > div.content > section > h1.disabled, #ui-container > div.views > section.carspecs > div > div.content > section > h1.disabled-forced {
  pointer-events: none;
  opacity: .5;
}

#ui-container > div.views > section.carspecs > div > div.content > section > h1:hover {
  color: #00a3e0;
  opacity: 1;
}

#ui-container > div.views > section.carspecs > div > div.content > section > div {
  height: 100%;
  transition: height .5s ease-out;
  display: none;
  overflow: hidden;
}

#ui-container > div.views > section.carspecs > div > div.content > section > div > div.gallery-module {
  width: 37.6rem;
}

#ui-container > div.views > section.carspecs > div > div.content > section > div > div.gallery-module > ul > li {
  text-align: left;
}

#ui-container > div.views > section.carspecs > div > div.content > section > div > div.gallery-module > ul > li > img {
  height: 25rem;
}

#ui-container > div.views > section.carspecs > div > div.content > section > div > h2, #ui-container > div.views > section.carspecs > div > div.content > section > div > div.gallery-module > ul > li > span > h2 {
  padding: 2rem 0;
  font-size: 1.4rem;
}

#ui-container > div.views > section.carspecs > div > div.content > section > div > span {
  pointer-events: all;
  padding-bottom: 2rem;
  display: block;
}

#ui-container > div.views > section.carspecs > div > div.content > section > div ol {
  margin: .5rem 0;
}

#ui-container > div.views > section.carspecs > div > div.content > section > div ol > li {
  margin: .25rem 0 0 2rem;
  list-style: disc;
}

#ui-container > div.views > section.carspecs > div > div.content > section.selected > h1 {
  pointer-events: unset;
  cursor: auto;
  color: unset;
  pointer-events: all;
  font-size: 2.4rem;
}

#ui-container > div.views > section.carspecs > div > div.content > section.selected > h1:hover, #ui-container > div.views > section.carspecs > div > div.content > section.selected > h1:active, #ui-container > div.views > section.carspecs > div > div.content > section.selected > h1.disabled, #ui-container > div.views > section.carspecs > div > div.content > section.selected > h1.disabled-forced {
  pointer-events: auto;
  opacity: unset;
}

#ui-container > div.views > section.carspecs > div > div.content > section.selected > div {
  display: block;
}

#ui-container > div.views > section.carspecs > div > div.content > section img[data-color-filter] {
  display: none;
}

#ui-container > div.views > section.carspecs > div > div.content > section img[data-color-filter].selected {
  display: block;
}

#ui-container > div.views > section.carspecs > div.interior > div.content > section {
  display: none;
}

#ui-container > div.views > section.carspecs > div.interior > div.content > section[data-interior] {
  display: block;
}

#ui-container > div.views > section.gallery, #ui-container > div.views > section.video {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#ui-container > div.views > section.gallery > h1, #ui-container > div.views > section.video > h1 {
  text-align: left;
  width: 94.8rem;
  margin-bottom: 1.5rem;
  font-size: 3.2rem;
  display: inline-block;
}

#ui-container > div.views > section.gallery div.gallery-module, #ui-container > div.views > section.video div.gallery-module {
  width: 94.8rem;
  height: 63rem;
  display: inline-block;
}

#ui-container > div.views > section.gallery.video div.gallery-module, #ui-container > div.views > section.video.video div.gallery-module {
  height: unset;
}

#ui-container > div.views > section.gallery.video div.gallery-module > ul, #ui-container > div.views > section.video.video div.gallery-module > ul {
  height: 53rem;
}

#ui-container > div.views > section.gallery.video div.gallery-module > div.pager-video, #ui-container > div.views > section.video.video div.gallery-module > div.pager-video {
  width: 100%;
  padding: 1.5rem 0 0;
  font-size: 1.2rem;
}

#ui-container > div.views > section.gallery.video div.gallery-module > div.pager-video > ul, #ui-container > div.views > section.video.video div.gallery-module > div.pager-video > ul {
  display: flex;
}

#ui-container > div.views > section.gallery.video div.gallery-module > div.pager-video > ul > li, #ui-container > div.views > section.video.video div.gallery-module > div.pager-video > ul > li {
  pointer-events: all;
  cursor: pointer;
  color: inherit;
  width: 14.5rem;
  font-family: inherit;
}

#ui-container > div.views > section.gallery.video div.gallery-module > div.pager-video > ul > li:hover, #ui-container > div.views > section.video.video div.gallery-module > div.pager-video > ul > li:hover {
  opacity: .75;
}

#ui-container > div.views > section.gallery.video div.gallery-module > div.pager-video > ul > li:active, #ui-container > div.views > section.video.video div.gallery-module > div.pager-video > ul > li:active {
  opacity: .6;
}

#ui-container > div.views > section.gallery.video div.gallery-module > div.pager-video > ul > li.disabled, #ui-container > div.views > section.gallery.video div.gallery-module > div.pager-video > ul > li.disabled-forced, #ui-container > div.views > section.video.video div.gallery-module > div.pager-video > ul > li.disabled, #ui-container > div.views > section.video.video div.gallery-module > div.pager-video > ul > li.disabled-forced {
  pointer-events: none;
  opacity: .5;
}

#ui-container > div.views > section.gallery.video div.gallery-module > div.pager-video > ul > li:not(#ui-container > div.views > section.gallery.video div.gallery-module > div.pager-video > ul > li:first-child, #ui-container > div.views > section.video.video div.gallery-module > div.pager-video > ul > li:first-child), #ui-container > div.views > section.video.video div.gallery-module > div.pager-video > ul > li:not(#ui-container > div.views > section.gallery.video div.gallery-module > div.pager-video > ul > li:first-child, #ui-container > div.views > section.video.video div.gallery-module > div.pager-video > ul > li:first-child) {
  margin-left: 1rem;
}

#ui-container > div.views > section.gallery.video div.gallery-module > div.pager-video > ul > li > img, #ui-container > div.views > section.video.video div.gallery-module > div.pager-video > ul > li > img {
  border: 2px solid #0000;
  width: 100%;
}

#ui-container > div.views > section.gallery.video div.gallery-module > div.pager-video > ul > li > span, #ui-container > div.views > section.video.video div.gallery-module > div.pager-video > ul > li > span {
  margin-top: 1rem;
  display: block;
}

#ui-container > div.views > section.gallery.video div.gallery-module > div.pager-video > ul > li.selected, #ui-container > div.views > section.video.video div.gallery-module > div.pager-video > ul > li.selected {
  pointer-events: unset;
  cursor: auto;
}

#ui-container > div.views > section.gallery.video div.gallery-module > div.pager-video > ul > li.selected:hover, #ui-container > div.views > section.gallery.video div.gallery-module > div.pager-video > ul > li.selected:active, #ui-container > div.views > section.gallery.video div.gallery-module > div.pager-video > ul > li.selected.disabled, #ui-container > div.views > section.gallery.video div.gallery-module > div.pager-video > ul > li.selected.disabled-forced, #ui-container > div.views > section.video.video div.gallery-module > div.pager-video > ul > li.selected:hover, #ui-container > div.views > section.video.video div.gallery-module > div.pager-video > ul > li.selected:active, #ui-container > div.views > section.video.video div.gallery-module > div.pager-video > ul > li.selected.disabled, #ui-container > div.views > section.video.video div.gallery-module > div.pager-video > ul > li.selected.disabled-forced {
  pointer-events: auto;
  opacity: unset;
}

#ui-container > div.views > section.gallery.video div.gallery-module > div.pager-video > ul > li.selected > img, #ui-container > div.views > section.video.video div.gallery-module > div.pager-video > ul > li.selected > img {
  border: 2px solid #00a3e0;
}

#ui-container > div.views > section.videochat {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#ui-container > div.views > section.videochat > div {
  background-color: #000;
  width: 80%;
  height: 90%;
  display: inline-block;
  position: relative;
}

#ui-container > div.views > section.videochat > div > iframe {
  width: 100%;
  height: 100%;
}

#ui-container #menu-videochat {
  z-index: 1;
  align-items: center;
  display: none;
  position: absolute;
  top: calc(100vh - 37.5rem);
  right: 1rem;
}

#ui-container #menu-videochat button, #ui-container #menu-videochat a {
  position: unset;
  padding: 0;
  font-size: 1.2rem;
}

#ui-container #menu-videochat > button {
  background: none;
  display: inline-block;
}

#ui-container #menu-videochat > button.left {
  color: #fff;
  background: linear-gradient(90deg, #000 0%, #2a2a2a 100%);
  border-radius: 5px;
  outline: 1px solid #2a506b;
  align-items: center;
  height: 6.5rem;
  margin-right: 2rem;
  padding: 0 1.5rem 0 1.2rem;
  display: inline-flex;
  position: relative;
}

#ui-container #menu-videochat > button.left:before {
  content: "";
  border-radius: 5px;
  outline: .5rem solid #00a3e033;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#ui-container #menu-videochat > button.left:after {
  content: "";
  background: none;
  border: .5rem solid #00a3e0;
  border-color: #00a3e0 #00a3e0 #0000 #0000;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(95%, -30%)rotate(45deg);
}

#ui-container #menu-videochat > button.left:hover {
  opacity: 1;
  color: #00a3e0;
}

#ui-container #menu-videochat > button.left > a {
  background: #787b801a;
  border-radius: 50%;
  width: 3.2rem;
  height: 3.2rem;
  margin: 0 1.5rem 0 0;
  position: relative;
}

#ui-container #menu-videochat > button.left > a svg {
  width: 1rem;
  height: 1rem;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#ui-container #menu-videochat > button.left > span {
  text-align: left;
  display: inline-block;
}

#ui-container #menu-videochat > button.right {
  border-radius: 50%;
  outline: .5rem solid #00a3e033;
  width: 9rem;
  height: 9rem;
  overflow: hidden;
}

#ui-container #menu-videochat > button.right > img {
  width: auto;
  height: 100%;
}

#cars-main-container {
  opacity: 0;
  background-color: #000;
  height: 100%;
  transition: opacity 1s;
  display: none;
  position: relative;
}

#canvas-container {
  z-index: 8;
  pointer-events: all;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

#canvas-container > canvas {
  width: 100%;
  height: 100%;
}

#canvas-container header {
  display: block;
}

#canvas-container button {
  font-size: 1.6rem;
}

#canvas-container .btn-close {
  top: unset;
  right: unset;
  bottom: 4rem;
  left: 4rem;
}

#canvas-container .btn-close > svg {
  width: 3.6rem;
  height: 2rem;
  margin: 0 1.5rem -.3rem 0;
}

#canvas-container .btn-speech {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 4rem;
  right: 4rem;
}

#canvas-container .btn-speech > svg {
  width: 6rem;
  height: 6rem;
  margin-bottom: 1.5rem;
  display: block;
}

#canvas-container .btn-speech.playing > svg use {
  stroke: #00a3e0;
}

#canvas-container > svg {
  width: 8rem;
  height: 8rem;
  position: absolute;
  bottom: 4rem;
  left: 50%;
  transform: translateX(-50%);
}

#platform-container {
  pointer-events: all;
  width: 177.78%;
  height: 100%;
  margin-left: -88.89%;
  position: absolute;
  left: 50%;
}

@media (max-height: 450px) {
  #platform-container {
    width: 186.669%;
    height: 105%;
    margin-left: -93.3345%;
  }
}

#platform-container * {
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

#platform-container > * {
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#platform-container .walls {
  top: 11%;
  left: -5%;
}

#platform-container .walls > li {
  background-image: url("wall.9c517e97.png");
  width: 67.44%;
  height: 67.53%;
  position: absolute;
  left: 60%;
}

#platform-container .walls > li:first-child {
  left: unset;
  right: 50%;
}

#platform-container .walls > li:nth-child(2) {
  transform: scaleX(-1);
}

#platform-container .walls > li > span {
  float: right;
  background-color: #7ecdea;
  width: .45%;
  height: 67.11%;
  margin-top: .5%;
  margin-left: 4.82%;
  transition: background-color 1s linear;
  transform: skew(-15.2deg);
}

#platform-container .walls > li > span:first-child {
  margin-right: 28%;
}

#platform-container .lights {
  background-image: linear-gradient(#000 2%, #000000bf 4%, #0000 8%);
  flex-direction: row;
  display: flex;
  top: 10%;
  left: 2.5%;
}

#platform-container .lights > li {
  pointer-events: none;
  background-image: url("top_light.2cf34b98.png");
  width: 25.52%;
  height: 26.02%;
}

#platform-container .lights > li:not(:first-child) {
  margin-left: -3%;
}

#platform-container .stands {
  width: 205.73%;
  display: flex;
  top: 57%;
  left: 0;
}

#platform-container .stands > li {
  flex-grow: 0;
  flex-shrink: 0;
  width: 25%;
  height: 20.83%;
  display: inline-flex;
  position: relative;
}

#platform-container .stands > li > div {
  position: absolute;
}

#platform-container .stands > li > div.main_stand {
  background-image: url("main_stand.bdf565f2.png");
  width: 100%;
  height: 100%;
}

#platform-container .stands > li > div.main_stand > span {
  position: absolute;
}

#platform-container .stands > li > div.main_stand > span.stand {
  background-image: url("stand.8078bb4a.png");
  width: 60.51%;
  height: 159.44%;
  bottom: 59%;
  left: 17%;
}

#platform-container .stands > li > div.main_stand > span.stand.locked {
  background-image: url("stand_locked.8a9950f8.png");
  width: 41.52%;
  height: 28.33%;
  bottom: 63%;
  left: 26%;
}

#platform-container .stands > li > div.main_stand > span.car {
  top: 50%;
  left: 50%;
}

#platform-container .stands > li > div.main_stand > span.car.button:hover, #platform-container .stands > li > div.main_stand > span.car.button:active {
  opacity: 1;
}

#platform-container .stands > li > div.main_stand > span.car > canvas {
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0);
}

#platform-container .stands > li > div.main_stand > span.car.car_208 {
  width: 53.92%;
  height: 158.33%;
  transform: translate(-55%, -93%);
}

#platform-container .stands > li > div.main_stand > span.car.car_308 {
  width: 56.62%;
  height: 166.25%;
  transform: translate(-54%, -85%);
}

#platform-container .stands > li > div.main_stand > span.car.car_408 {
  width: 52.58%;
  height: 154.38%;
  transform: translate(-54%, -92%);
}

#platform-container .stands > li > div.main_stand > span.car.car_508 {
  width: 51.23%;
  height: 150.42%;
  transform: translate(-54%, -94%);
}

#platform-container .stands > li > div.main_stand > span.car.car_2008 {
  width: 52.58%;
  height: 154.38%;
  transform: translate(-55%, -93%);
}

#platform-container .stands > li > div.main_stand > span.car.car_3008 {
  width: 52.58%;
  height: 154.38%;
  transform: translate(-55%, -95%);
}

#platform-container .stands > li > div.main_stand > span.car.car_rifter, #platform-container .stands > li > div.main_stand > span.car.car_5008 {
  width: 53.92%;
  height: 158.33%;
  transform: translate(-54%, -93%);
}

#platform-container .stands > li > div.main_stand > span.car.locked {
  background-image: url("car_locked.933f2895.png");
  width: 42.78%;
  height: 68.33%;
  transform: translate(-53%, -123%);
}

#platform-container .stands > li > span {
  background-image: url("ground_light.8718e4dc.png");
  width: 12.2%;
  height: 118.67%;
  position: absolute;
  bottom: 16%;
  left: -20%;
}

#platform-container .stands > li > span.light-left {
  bottom: 42%;
  left: 84%;
  transform: rotate(4deg);
}

/*# sourceMappingURL=index.bb1d15d1.css.map */
